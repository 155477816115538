<script lang="ts" setup>
  import { useElementsStore } from '~/stores/elements';
  import VButton from '~/components/ui/VButton.vue';
  import useStatefulCookie from '~/composables/useStatefulCookies';
  import type { CookieRef } from '#app';

  const { getElementByRoute } = useElementsStore();

  const isTargetKgDomain = useState('isTargetKgDomain');
  const { state } = storeToRefs(useAuthStore());
  const { screenSizes } = useScreen();
  const { hostname } = useRequestURL();

  const currentBanner = computed(() => getElementByRoute());
  const formattedLink = computed(() =>
    currentBanner.value?.link
      ?.replace('{token}', state.value.token!)
      .replace('{refresh_token}', state.value.refresh!)
      .replace('{domain}', hostname),
  );
  const hiddenBanners = ref<CookieRef<string> | null>(null);

  watchEffect(() => {
    if (currentBanner.value) {
      hiddenBanners.value = useStatefulCookie<string>(`${currentBanner.value.key}_${currentBanner.value.id}`, {}, 30);
    }
  });

  const hideBanner = () => {
    if (currentBanner.value && hiddenBanners.value) {
      hiddenBanners.value.value = 'hidden';
    }
  };

  const showBanner = computed(() => currentBanner.value && hiddenBanners.value?.value !== 'hidden');
</script>

<template>
  <teleport to="body">
    <a
      v-if="currentBanner && showBanner && !isTargetKgDomain"
      :href="formattedLink"
      class="v-advertising"
      :key="currentBanner.key"
    >
      <picture>
        <source
          v-if="currentBanner?.image_mobile"
          :srcset="currentBanner?.image_mobile"
          :media="`(max-width: ${screenSizes.retina})`"
        />
        <source
          v-if="currentBanner?.image_tablet"
          :srcset="currentBanner?.image_tablet"
          :media="`(max-width: ${screenSizes.tablet})`"
        />
        <img :src="currentBanner?.image" alt="" />
      </picture>
      <v-button
        class="v-advertising__close"
        size="small"
        icon="close"
        appearance="text"
        @click.stop.prevent="hideBanner"
      />
    </a>
  </teleport>
</template>

<style lang="scss" scoped>
  .v-advertising {
    position: relative;
    background-color: #0b0b0b;
    display: flex;
    justify-content: center;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: auto;
      padding: 0;
      border-radius: 50%;
      background-color: $main_grey_light;

      &:deep(svg) {
        color: $main_grey_dark !important;
      }
      &:hover {
        background-color: $main_grey_light !important;
      }
    }
    picture,
    img {
      width: 100%;
    }
  }
</style>

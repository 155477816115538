const prepositionalMonths = ['январе', 'феврале', 'марте', 'апреле', 'мае', 'июне', 'июле', 'августе', 'сентябре', 'октябре', 'ноябре', 'декабре'];
const unitFallbackTexts = {
  day: 'д.',
  hour: 'ч',
  minute: 'мин',
  second: 'сек',
  millisecond: 'мсек',
};

export const toTime = (seconds: number): string => {
  const ms = seconds * 1000;
  return new Date(ms).toISOString().substring(11, 19).replace(/00:/, '');
};

export const formatDateString = (date: string | null) => (date ? new Date(date).toLocaleDateString() : null);

export const divTime = (divider: number[], ms: number): number[] => {
  let save = ms;
  return divider.map(div => {
    const times = Math.floor(save / div);
    save = save % div;
    return times;
  });
};

export const createDurationFormatter = (
  locale: string,
  unitDisplay: 'short' | 'long' | 'narrow' = 'short',
  format: ('days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds')[] = ['days', 'hours', 'minutes'],
  minimumIntegerDigits: number = 1,
  removeZero: boolean = true,
  removeSpace: boolean = true,
) => {
  const divMod = (n: number, m: number) => [Math.floor(n / m), n % m];
  const timeUnitFormatter = (locale: string, unit: string, unitDisplay?: 'short' | 'long' | 'narrow') => {
    const format = Intl.NumberFormat(locale, { style: 'unit', unit, unitDisplay, minimumIntegerDigits }).format;

    return (time: number) => {
      try {
        return format(time);
      } catch {
        return time + ' ' + unitFallbackTexts[unit];
      }
    };
  };
  const fmtDays = timeUnitFormatter(locale, 'day', unitDisplay),
    fmtHours = timeUnitFormatter(locale, 'hour', unitDisplay),
    fmtMinutes = timeUnitFormatter(locale, 'minute', unitDisplay),
    fmtSeconds = timeUnitFormatter(locale, 'second', unitDisplay),
    fmtMilliseconds = timeUnitFormatter(locale, 'millisecond', unitDisplay),
    fmtList = new Intl.ListFormat(locale, {
      style: 'long',
      type: 'unit',
    });
  return (ms: number): string => {
    let milliseconds = ms;
    let days: number, hours: number, minutes: number, seconds: number;
    [days, milliseconds] = divMod(milliseconds, 864e5);
    [hours, milliseconds] = divMod(milliseconds, 36e5);
    [minutes, milliseconds] = divMod(milliseconds, 6e4);
    [seconds, milliseconds] = divMod(milliseconds, 1e3);

    const formatList = Object.entries({
      days: !removeZero || days ? fmtDays(days) : null,
      hours: !removeZero || hours ? fmtHours(hours) : null,
      minutes: !removeZero || minutes ? fmtMinutes(minutes) : null,
      seconds: !removeZero || seconds ? fmtSeconds(seconds) : null,
      milliseconds: !removeZero || milliseconds ? fmtMilliseconds(milliseconds) : null,
    }).reduce(
      (res, [key, value]) => {
        const _res = res;
        if (value !== null && format.includes(key as 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds')) {
          const newValue = removeSpace ? value.replace(/(\d+)\s+(?=\p{L})/gu, `$1`) : value;
          _res.push(newValue);
        }
        return _res;
      },
      <string[]>[],
    );

    try {
      return fmtList.format(formatList);
    } catch {
      return formatList.join(' ');
    }
  };
};
export const getTimeRemaining = (date: string, format: 'seconds' | 'minutes' | 'hours' | 'days' = 'days'): number => {
  const endDate = new Date(date);
  const timeUnits = {
    seconds: 1000,
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
  };

  return Math.max(0, Math.round((endDate.getTime() - Date.now()) / timeUnits[format]));
};

export const getPrepositionalDateLabel = (date: string) => {
  const dateVal = new Date(date);

  return prepositionalMonths[dateVal.getMonth()] + ' ' + dateVal.getFullYear();
};
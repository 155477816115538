import type { TPlatform } from '~/services/types';

export const useNavigator = () => {
  const { state } = storeToRefs(useAuthStore());
  const reqAgent = useRequestHeader('user-agent');
  const isMobile = ref(false);
  const isIOS = ref(false);
  const isAndroid = ref(false);

  let userAgent = '';
  if (import.meta.server && reqAgent) {
    userAgent = reqAgent;
  } else if (import.meta.client) {
    userAgent = navigator.userAgent || (window as any).opera;
  }

  onMounted(() => {
    if (import.meta.client) {
      userAgent = navigator.userAgent || (window as any).opera;
    }
  });

  // Check for mobile
  isMobile.value = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  // Check for iOS
  isIOS.value = /iPad|iPhone|iPod/.test(userAgent);

  // Check for Android
  isAndroid.value = /Android/i.test(userAgent);

  const platform = computed<TPlatform>(() => {
    if (/iPhone|iPad|iPod/.test(userAgent)) return 'ios';
    if (/Android/.test(userAgent)) return 'android';
    if (/AppleTV/i.test(userAgent)) return 'tvos';
    if (/AndroidTV/i.test(userAgent)) return 'androidtv';
    if (/Tizen/i.test(userAgent)) return 'tizen';
    if (/WebOS|webOS/i.test(userAgent)) return 'webos';
    if (state.value.loggedIn) return 'land_auth';
    return 'landing';
  });

  return {
    isMobile,
    isIOS,
    isAndroid,
    platform,
  };
};

import { defineStore } from 'pinia';
import { getSiteElements } from '~/services/modules/siteElements.service';
import type { ISiteElement } from '~/services/types';

enum EnumBanner {
  'browse' = 'catalog_banner',
  'sports' = 'sport_banner',
  'home' = 'main_page_banner',
  'movie-slug' = 'film_detail_banner',
  'favorites' = 'favorite_banner',
  'search' = 'search_banner',
  'subscriptions' = 'tariffs_header_banner',
  'collections' = 'all_collections_banner',
  'collections-slug' = 'incollection_banner',
}

export const useElementsStore = defineStore('useElementsStore', () => {
  // const elements = ref<ISiteElement[]>([]);
  const route = useRoute();
  const { platform } = useNavigator();

  const fetchElements = async () => {
    const { data, isFetching } = getSiteElements({
      platform: platform.value,
      page_size: 100,
    });
    if (data.value) {
      elements.value = data.value;
    }
  };
  const params = computed(() => ({
    platform: platform.value,
    page_size: 100,
  }));
  const { data: elements } = getSiteElements(params);

  const getElementByRoute = (): ISiteElement | null => {
    if (!elements.value?.length) return null;
    const elementKey = EnumBanner[String(route.name) as keyof typeof EnumBanner];
    const banner = elements.value.find(({ key }) => key === elementKey) ?? null;
    if (String(route.name).includes('slug')) {
      if (banner?.slug === route.params.slug) return banner;
      return null;
    } else return banner;
  };

  return {
    elements,
    platform,
    fetchElements,
    getElementByRoute,
  };
});

import { errorHandler, getSubmitFn } from '~/helpers/common';
import { loginValidation } from '~/components/modals/auth/validation';
import { checkAccount, submitAccount } from '~/services/modules/auth.service';
import { useQueryClient } from '@tanstack/vue-query';
import { useRedirect } from '~/composables/useRedirect';
import useStatefulCookie from '~/composables/useStatefulCookies';
import type { AdvertiseParams, CityAdsParams } from '~/types/common';
import { cleanPhoneNumber, removeSpaces } from '~/helpers/processing';

const AuthModal = defineAsyncComponent(() => import('~/components/modals/auth/Index.vue'));
const { onReveal, onClose } = createModal(AuthModal);

interface IVeeAttrs {
  handleSubmit: Function;
  setFieldError: Function;
}

const step = ref(1);
const state = reactive({ login: '', code: '' });
const isPhoneLogin = computed(() => /^[\d+\-\(\)\s]+$/.test(state.login));

export const useAuth = (onSuccessAuthAndClose = () => {}) => {
  const isPending = ref<boolean>(false);
  const isPaused = ref<boolean>(false);
  let startLeaveTabTime: number = 0;
  let endLeaveTabTime: number = 0;
  const timer = ref<number>(0);
  let repeatInterval: ReturnType<typeof setInterval> | undefined;
  const cityAds = useStatefulCookie<CityAdsParams | null>('cityAds');
  const advertise = useStatefulCookie<AdvertiseParams | null>('advertise');
  const referrer = useStatefulCookie<string | null>('referrer');
  const promocode = useStatefulCookie<string | null>('promo_code');
  const isTargetKgDomain = useState('isTargetKgDomain');
  const { mutate } = checkAccount();
  const { mutate: submitMutate, isPending: submitPending } = submitAccount();
  const { setUser, setState, setCreditsCookies } = useAuthStore();
  const query = useQueryClient();
  const { redirectToPersonalDomain } = useRedirect();
  const config = useRuntimeConfig();
  const { reachGoal, metricGoals } = useMetrics();

  const { startCheckCaptcha, getCaptchaToken } = useCaptchaStore();

  const textHeader = computed(() => (!isTargetKgDomain.value ? `Введите номер телефона или e-mail` : 'Введите e-mail'));
  const textInput = computed(() => (!isTargetKgDomain.value ? 'Введите телефон или e-mail' : 'Введите e-mail'));
  const authRoute = computed(
    () => config.public.authUrl || (isTargetKgDomain.value ? 'https://kinoflex.kg/' : 'https://kino-flex.online'),
  );

  const getFormattedLoginPayload = () =>
    isPhoneLogin.value ? { phone: cleanPhoneNumber(state.login) } : { email: removeSpaces(state.login) };

  const onCloseHandler = () => {
    onClose();
    if (step.value === 3) {
      onSuccessAuthAndClose();
    }
  };

  const validateLogin = async ({ handleSubmit, setFieldError }: IVeeAttrs) => {
    handleSubmit(
      getSubmitFn(loginValidation().start, () => {
        startCheckCaptcha(() => {
          startSendCode({ setFieldError });
        });
      }),
    );
  };

  const startSendCode = async ({ setFieldError }: IVeeAttrs) => {
    const payload: {
      phone?: string;
      email?: string;
      recaptcha?: string;
    } = {
      ...getFormattedLoginPayload(),
    };

    try {
      isPending.value = true;
      payload.recaptcha = getCaptchaToken();

      mutate(payload, {
        onSuccess(data) {
          startTimer(data.time_to_call);
          step.value++;
        },
        onError(e) {
          errorHandler({ message: e }, (msg: string) => setFieldError('login', msg));
        },
        onSettled() {
          isPending.value = false;
        },
      });
    } catch (e) {}
  };
  const submitLogin = async ({ handleSubmit, setFieldError }: IVeeAttrs) => {
    handleSubmit(
      getSubmitFn(loginValidation().check, values => {
        const payload: {
          [p: string]: string;
        } = {
          code: values.code,
          ...getFormattedLoginPayload(),
        };
        const referrerParams = cityAds.value ?? advertise.value;
        if (referrer.value) payload.referer = referrer.value;
        if (referrerParams) {
          payload.partner_uid = cityAds.value?.click_id! || advertise.value?.uid!;
          payload.wm_id = referrerParams?.utm_campaign;
          payload.source = referrerParams?.utm_source;
        }
        if (isTargetKgDomain.value) payload.country = 'KG';

        submitMutate(payload, {
          onSuccess(data) {
            setState(data);
            setCreditsCookies(data);
            setUser(data.user);
            $updateQueries();
            cityAds.value = null;
            advertise.value = null;
            referrer.value = null;
            reachGoal(data.is_registration ? metricGoals.registrationSuccess : metricGoals.authSuccess);
            if (data.is_registration) step.value++;
            else {
              redirect();
              onClose();
              onSuccessAuthAndClose();
            }
          },
          onError(e) {
            errorHandler({ message: e }, (msg: string) => setFieldError('code', msg));
          },
        });
      }),
    );
  };
  const resendCode = async ({ handleSubmit, setFieldError }: IVeeAttrs) => {
    await startCheckCaptcha(() => {
      const payload: {
        isPhoneLogin?: string;
        recaptcha?: string;
      } = {
        ...getFormattedLoginPayload(),
      };
      try {
        payload.recaptcha = getCaptchaToken();
        mutate(payload, {
          onSuccess(data) {
            startTimer(data.time_to_call);
            state.code = '';
            setTimeout(() => {
              setFieldError('code', null);
            }, 10);
          },
          onError(e) {
            errorHandler({ message: e }, (msg: string) => setFieldError('code', msg));
          },
        });
      } catch (e) {
        console.log(e);
      }
    });
  };
  const startTimer = (timeRemains: number) => {
    timer.value = timeRemains;
    repeatInterval = setInterval(() => {
      if (isPaused.value) return;
      timer.value -= 1;
      if (timer.value <= 0) {
        timer.value = 0;
        clearInterval(repeatInterval);
        return;
      }
    }, 1000);
  };
  const clearBlurTimer = () => {
    startLeaveTabTime = 0;
    endLeaveTabTime = 0;
    isPaused.value = false;
  };
  const resetStep = () => (step.value = 1);
  const $updateQueries = async () => {
    query.removeQueries({ type: 'inactive' });
    await query.resetQueries();
    await query.invalidateQueries();
  };
  const redirect = () => redirectToPersonalDomain({ promo_code: promocode.value });

  const timerHasExpired = () => timer.value <= 0;
  const getCurrentDateInMs = () => new Date().getTime();
  const onBlurTab = () => {
    isPaused.value = true;
    startLeaveTabTime = getCurrentDateInMs();
  };
  const onFocusTab = () => {
    if (!startLeaveTabTime) return;
    if (timerHasExpired()) {
      timer.value = 0;
      return;
    }
    isPaused.value = false;
    endLeaveTabTime = getCurrentDateInMs();
    const difference = ((endLeaveTabTime - startLeaveTabTime) / 1000).toFixed(0);
    timer.value = timer.value - +difference;
    clearBlurTimer();
  };
  // onMounted(() => resetStep());
  return {
    state,
    step,
    timer,
    repeatInterval,
    isPaused,
    startLeaveTabTime,
    endLeaveTabTime,
    textHeader,
    textInput,
    authRoute,
    isPending,
    isPhoneLogin,
    submitPending,
    onReveal,
    onClose: onCloseHandler,
    resetStep,
    validateLogin,
    submitLogin,
    resendCode,
    startTimer,
    $updateQueries,
    redirect,
    clearBlurTimer,
    timerHasExpired,
    onBlurTab,
    onFocusTab,
  };
};

import type { IInstall } from './types';
import { getStoresInfo } from '~/services/modules/utility.service';

export const useInstall = () => {
  const isTargetKgDomain = useState('isTargetKgDomain');
  const route = useRoute();

  const { data: storesInfo, suspense } = getStoresInfo({
    params: { country: isTargetKgDomain.value ? 'kg' : 'ru' },
  });
  onServerPrefetch(async () => await suspense());

  const iosTabs = computed(() =>  [
    {
      title: 'App Store',
      hide: !storesInfo.value?.ios_link,
      items: [
        {
          icon: 'download',
          info: 'Нажмите кнопку “Перейти” для перехода в магазин приложений App Store',
          link: storesInfo.value?.ios_link,
          linkText: 'Перейти',
        },
      ],
    },
    {
      title: 'TestFlight',
      hide: !storesInfo.value?.ios_link_testflight,
      items: [
        {
          icon: 'download',
          info: 'Нажмите кнопку «Перейти» для перехода в TestFlight',
          link: storesInfo.value?.ios_link_testflight,
          linkText: 'Перейти',
        },
        {
          icon: 'install',
          info: 'Установите приложение в TestFlight, следуя инструкциям на экране',
        },
      ],
    },
  ]);
  const androidTabs = computed(() => [
    {
      title: 'Google Play',
      hide: !storesInfo.value?.google_play_link,
      items: [
        {
          icon: 'download',
          info: 'Нажмите кнопку “Перейти” для перехода в магазин приложений Google Play',
          link: storesInfo.value?.google_play_link,
          linkText: 'Перейти',
        },
      ],
    },
    {
      title: 'RuStore',
      hide: !storesInfo.value?.rustore_link,
      items: [
        {
          icon: 'find',
          info: 'Запустите магазин приложений RuStore и в поисковой строке введите “FLEX”',
          link: storesInfo.value?.rustore_link,
          linkText: 'Перейти',
        },
        {
          icon: 'install',
          info: 'Установите приложение, нажав кнопку “Установить”',
        },
      ],
    },
    {
      title: 'Ручная установка',
      hide: !storesInfo.value?.android_app_link,
      items: [
        {
          icon: 'download',
          info: 'Скачайте файл Flex.apk',
          link: storesInfo.value?.android_app_link,
          fileName: 'Flex.apk',
          linkText: 'Скачать',
        },
        {
          icon: 'downloads',
          info: 'Перейдите в загрузки и запустите установку приложения',
        },
        {
          icon: 'settings',
          info: 'В случае ошибки при установке зайдите в настройки и разрешите установку приложений из неизвестных источников',
        },
        {
          icon: 'install',
          info: 'Установите приложение, следуя инструкциям на вашем экране',
        },
      ],
    },
  ]);
  const appleTvTabs = computed(() => [
    {
      title: 'App Store',
      hide: !storesInfo.value?.tvos_link,
      items: [
        {
          icon: 'download',
          info: 'Запустите магазин приложений App Store',
        },
        {
          icon: 'find',
          info: 'В поисковой строке введите "FLEX - фильмы и сериалы"',
        },
        {
          icon: 'install',
          info: 'Установите приложение, следуя инструкциям на экране',
        },
      ],
    },
    {
      title: 'TestFlight',
      hide: !storesInfo.value?.tvos_link_testflight,
      items: [
        {
          icon: 'download',
          info: 'Нажмите кнопку «Перейти» для перехода в TestFlight с вашего iOS-устройства',
          link: storesInfo.value?.tvos_link_testflight,
          linkText: 'Перейти',
        },
        {
          icon: 'download-usb',
          info: 'Добавьте приложение в TestFlight, следуя инструкциям на экране',
        },
        {
          icon: 'downloads',
          info: 'Откройте TestFlight на вашем Apple TV',
        },
        {
          icon: 'install',
          info: 'Установите приложение FLEX на Apple TV',
        },
      ],
    },
  ]);
  const androidTvTabs = computed(() => [
    {
      title: 'Google Play',
      hide: !storesInfo.value?.google_play_tv_link,
      items: [
        {
          icon: 'download',
          info: 'Запустите магазин приложений Google Play',
        },
        {
          icon: 'find',
          info: 'В поисковой строке введите "FLEX - фильмы и сериалы"',
        },
        {
          icon: 'install',
          info: 'Установите приложение, следуя инструкциям на экране',
        },
      ],
    },
    {
      title: 'RuStore',
      hide: !storesInfo.value?.rustore_tv_link,
      items: [
        {
          icon: 'download',
          info: 'Запустите магазин приложений RuStore',
        },
        {
          icon: 'find',
          info: 'В каталоге приложений RuStore найдите «FLEX - фильмы и сериалы».',
        },
        {
          icon: 'install',
          info: 'Установите приложение, следуя инструкциям на экране',
        },
      ],
    },
    {
      title: 'Ручная установка',
      hide: !storesInfo.value?.android_tv_app_link,
      items: [
        {
          icon: 'download',
          info: 'Скачайте файл FlexTV.apk',
          link: storesInfo.value?.android_tv_app_link,
          fileName: 'FlexTV.apk',
          linkText: 'Скачать',
        },
        {
          icon: 'copy',
          info: 'Скопируйте FlexTV.apk на USB-адаптер (флешку)',
        },
        {
          icon: 'download-usb',
          info: 'Подключите USB-адаптер (флешку) к TV-приставке Android TV или к телевизору с операционной системой Android',
        },
        {
          icon: 'downloads',
          info: 'Запустите файловый менеджер на приставке или телевизоре',
        },
        {
          icon: 'install',
          info: 'Установите приложение, следуя инструкциям на экране',
        },
      ],
    },
  ]);
  const samsungTabs = computed(() => [
    {
      title: 'Samsung',
      hide: !storesInfo.value?.samsung_store,
      items: [
        {
          icon: 'find',
          info: 'Запустите магазин приложений Samsung в меню телевизора и в поисковой строке введите "FLEX"',
        },
        {
          icon: 'install',
          info: 'Установите приложение, нажав кнопку “Установить”',
        },
      ],
    },
    {
      title: 'Ручная установка',
      hide: !storesInfo.value?.tizen_app_link,
      items: [
        {
          icon: 'download',
          info: 'Скачайте файл Flex.wgt',
          link: storesInfo.value?.tizen_app_link,
          fileName: 'Flex.wgt',
          linkText: 'Скачать',
        },
        {
          icon: 'install',
          info: 'Установите приложение, следуя инструкциям на экране',
          link: '/tizen-manual/Tizen.pdf',
          linkText: 'Просмотреть',
        },
      ],
    },
  ]);
  const yandexTabs = computed(() => [
    {
      title: 'Яндекс ТВ',
      hide: !storesInfo.value?.yandex_tv_store,
      items: [
        {
          icon: 'find',
          info: 'Запустите магазин приложений на Яндекс ТВ в меню телевизора и в поисковой строке введите "FLEX"',
        },
        {
          icon: 'install',
          info: 'Установите приложение, нажав кнопку “Установить”',
        },
      ],
    },
    {
      title: 'Ручная установка',
      hide: !storesInfo.value?.android_tv_app_link,
      items: [
        {
          icon: 'download',
          info: 'Скачайте файл FlexTV.apk',
          link: storesInfo.value?.android_tv_app_link,
          fileName: 'FlexTV.apk',
          linkText: 'Скачать',
        },
        {
          icon: 'copy',
          info: 'Скопируйте FlexTV.apk на USB-адаптер (флешку)',
        },
        {
          icon: 'download-usb',
          info: 'Подключите USB-адаптер (флешку) к Яндекс ТВ',
        },
        {
          icon: 'downloads',
          info: 'Запустите файловый менеджер на приставке или телевизоре',
        },
        {
          icon: 'install',
          info: 'Установите приложение, следуя инструкциям на экране',
        },
      ],
    },
  ]);
  const lgTabs = computed(() => [
    {
      title: 'LG',
      hide: !storesInfo.value?.lg_store,
      items: [
        {
          icon: 'find',
          info: 'Запустите магазин приложений на LG в меню телевизора и в поисковой строке введите "FLEX"',
        },
        {
          icon: 'install',
          info: 'Установите приложение, нажав кнопку “Установить”',
        },
      ],
    },
    {
      title: 'Ручная установка',
      hide: !storesInfo.value?.webos_app_link,
      items: [
        {
          icon: 'download',
          info: 'Скачайте файл Flex.ipk',
          link: storesInfo.value?.webos_app_link,
          fileName: 'Flex.ipk',
          linkText: 'Скачать',
        },
        {
          icon: 'install',
          info: 'Установите приложение, следуя инструкциям на экране',
          link: '/media/FlexLg.pdf',
          linkText: 'Просмотреть',
        },
      ],
    },
  ]);

  const data: IInstall[] = computed(() => [
    {
      slug: 'ios',
      title: 'Установка на iOS',
      icon: 'ios',
      metaTitle: 'Установить мобильное приложение  онлайн-кинотеатра FLEX на iPhone и iPad',
      metaDesc:
        'Скачайте и смотрите онлайн и офлайн фильмы и сериалы в приложении FLEX для телефонов и планшетов Apple',
      tabs: iosTabs.value,
    },
    {
      slug: 'android',
      title: 'Установка на Android',
      icon: 'android',
      metaTitle: 'Установить мобильное приложение  онлайн-кинотеатра FLEX  на Android',
      metaDesc:
        'Скачайте и смотрите онлайн и офлайн фильмы и сериалы в приложении FLEX для телефонов и планшетов Android, а также скачайте из RuStore',
      tabs: androidTabs.value,
    },
    {
      slug: 'apple-tv',
      title: 'Установка на Apple TV',
      icon: 'ios',
      metaTitle: 'Установить приложение онлайн-кинотеатра FLEX  на Apple TV',
      metaDesc:
        'Скачайте и смотрите на телевизоре онлайн фильмы и сериалы в приложении FLEX с помощью приставки Apple TV',
      tabs: appleTvTabs.value,
    },
    {
      slug: 'android-tv',
      title: 'Установка на Android TV',
      icon: 'android',
      metaTitle: 'Установить приложение онлайн-кинотеатра FLEX  на Android TV',
      metaDesc:
        'Скачайте и смотрите на телевизоре онлайн фильмы и сериалы в приложении FLEX с помощью приставки Android TV',
      tabs: androidTvTabs.value,
    },
    {
      slug: 'pwa',
      title: 'Установка веб-приложения (PWA)',
      icon: 'pwa',
      metaTitle: 'Установить веб-приложение (PWA) онлайн-кинотеатра FLEX на IOS, Android',
      metaDesc: 'смотрите онлайн и офлайн фильмы и сериалы в приложении FLEX для телефонов и планшетов IOS, Android',
      tabs: [
        {
          title: 'Ручная установка',
          items: [
            {
              icon: 'install',
              info: 'Установите приложение, следуя инструкциям на экране',
              link: '/media/PWA.pdf',
              linkText: 'Просмотреть ',
            },
          ],
        },
      ],
    },
    {
      slug: 'samsung',
      title: 'Установка на Samsung',
      icon: 'samsung',
      metaTitle: 'Установить приложение онлайн-кинотеатра FLEX на телевизор Samsung (Tizen | Smart TV)',
      metaDesc:
        'Скачайте и смотрите онлайн фильмы и сериалы  на телевизоре Samsung (Tizen | Smart TV) в приложении FLEX',
      tabs: samsungTabs.value,
    },
    {
      slug: 'yandex',
      title: 'Установка на Яндекс ТВ',
      icon: 'yandex',
      metaTitle: 'Установить приложение онлайн-кинотеатра FLEX на телевизор Яндекс ТВ (Smart TV)',
      metaDesc:
        'Скачайте и смотрите онлайн фильмы и сериалы на телевизоре с операционной системой Яндекс ТВ (Smart TV) или модулем с Яндекс ТВ  в приложении FLEX',
      tabs: yandexTabs.value,
    },
    {
      slug: 'lg',
      title: 'Установка на LG',
      icon: 'lg',
      metaTitle: 'Установить приложение онлайн-кинотеатра FLEX на телевизор LG  (WebOS)',
      metaDesc: 'Скачайте и смотрите онлайн фильмы и сериалы  на телевизоре LG (WebOS)в приложении FLEX',
      tabs: lgTabs.value,
    },
  ]);

  const currentPage = computed<IInstall | null>(
    () => data.value.find(item => item.slug === route.params?.slug) || null,
  );

  return {
    currentPage,

    iosTabs,
    androidTabs,
    appleTvTabs,
    androidTvTabs,
    samsungTabs,
    yandexTabs,
    lgTabs,
  };
};

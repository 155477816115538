<script setup lang="ts">
  import type { RouteLocationRaw } from 'vue-router';

  interface IProps {
    type?: string;
    to?: RouteLocationRaw;
    icon?: string;
    size?: 'large' | 'normal' | 'small';
    appearance?: 'fill' | 'outline' | 'text';
    color?: 'main' | 'secondary' | 'white' | 'gray' | 'transparent-08' | 'gradient' | 'white-2';
    media?: 'large' | 'normal' | 'small';
    link?: boolean;
    rounded?: boolean;
    loading?: boolean;
    disabled?: boolean | number;
    customTag?: string;
  }

  const props = withDefaults(defineProps<IProps>(), {
    type: 'button',
    size: 'large',
    appearance: 'fill',
    color: 'main',
    disabled: false,
  });
  const iconName = computed(() => `icon-${props.loading ? 'loading' : props.icon}`);
  const isDisabled = computed(() => (props.disabled ? 'disabled' : null) as string);
  const isType = computed(() => (props.type && !props.to ? 'type' : null) as string);
</script>

<template>
  <component
    :is="props.customTag ? props.customTag : props.to ? 'router-link' : 'button'"
    class="v-button"
    :class="[
      `v-button--color-${props.color}`,
      `v-button--media-${props.media}`,
      {
        'v-button--rounded': props.rounded,
        'v-button--loading': props.loading,
        'v-button--link': props.link,
      },
    ]"
    :data-appearance="props.appearance"
    :data-size="props.size"
    :[isType]="props.type"
    :to="props.to"
    :[isDisabled]="props.disabled"
  >
    <div class="v-button__inner">
      <component :is="iconName" v-if="props.icon || props.loading" />
      <slot v-if="!props.rounded && !props.loading"></slot>
    </div>
  </component>
</template>

<style lang="scss">
  $button-sizes: (
    'large': (
      'button-font-size': 18px,
      'button-padding': 16px 24px,
      'button-line-height': 28px,
      'button-border-radius': 16px,
      'rounded-padding': 18px,
      'svg-width': 24px,
      'svg-height': 24px,
    ),
    'normal': (
      'button-font-size': 16px,
      'button-padding': 12px 16px,
      'button-line-height': 24px,
      'button-border-radius': 12px,
      'rounded-padding': 12px,
      'svg-width': 24px,
      'svg-height': 24px,
    ),
    'small': (
      'button-font-size': 14px,
      'button-padding': 8px,
      'button-line-height': 20px,
      'button-border-radius': 8px,
      'svg-width': 16px,
      'svg-height': 16px,
      'rounded-padding': 10px,
    ),
  );
  :root {
    --color-main: #{$gradient-pink-purple};
    --color-main-outline: #{$main_purple_light};
    --color-secondary: #{$secondary_red_light};
    --color-white: #{$main_white};
    --color-gray: #{$secondary_grey_mid};
    --color-transparent-08: #{$transparent_08};

    --color-hover-outline: #{$main_grey_extra_dark};
    --color-hover: #{$gradient-pink-purple};
    --color-active: #{$gradient-pink-purple};
    --color-active-outline: #{$main_grey_extra_dark};
    --color-disabled: #{$main_grey_extra_dark};

    --opacity: 1;
    --opacity-hover: 0.8;
    --opacity-active: 0.6;
    --opacity-disabled: 0.5;
  }
  .v-button {
    $parent: &;
    --button-background: var(--color-main);
    --button-color: var(--color-white);
    --button-color-outline: var(--color-main-outline);
    --button-color-text: var(--color-main-outline);
    --button-hover: var(--color-hover);
    --button-hover-outline: var(--color-hover-outline);
    --button-active: var(--color-active);
    --button-active-outline: var(--color-active-outline);
    --button-disabled: var(--color-disabled);

    --button-opacity: var(--opacity);
    --button-opacity-hover: var(--opacity-hover);
    --button-opacity-active: var(--opacity-active);
    --button-opacity-disabled: var(--opacity-disabled);
    --button-outline-hover: #{$main_grey_extra_dark};
    --button-outline-active: #{$secondary_grey_dark};

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--button-padding);
    gap: 8px;
    border-radius: var(--button-border-radius);
    background: var(--button-background);
    color: var(--button-color);
    border: none;
    font-size: var(--button-font-size);
    font-style: normal;
    font-weight: 600;
    line-height: var(--button-line-height);
    transition: 0.3s;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;
    opacity: var(--button-opacity);
    z-index: 0;

    &:hover:not(&--loading):not(&[disabled]) {
      background: var(--button-hover);
      --button-opacity: var(--button-opacity-hover);
    }

    &:active:not(&--loading):not(&[disabled]) {
      background: var(--button-active);
      --button-opacity: var(--button-opacity-active);
    }

    &[disabled] {
      --button-background: var(--button-disabled);
      pointer-events: none;
      cursor: default;
    }

    svg {
      width: var(--svg-width);
      height: var(--svg-height);
    }

    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &[data-appearance='outline'] {
      box-shadow: inset 0 0 0 1px var(--button-color-outline);
      --button-background: transparent;
      --button-color: var(--button-color-outline);

      svg {
        transition: 0.3s;
        color: var(--button-color);
        fill: var(--button-color);
      }

      &:hover:not(#{$parent}--loading):not(&:disabled) {
        background-color: var(--button-hover-outline);
        --button-background: var(--button-hover);
        --button-color: var(--button-hover);
      }

      &:active:not(#{$parent}--loading):not(&:disabled) {
        background-color: var(--button-outline-active);
        --button-background: var(--button-active-outline);
        --button-color: var(--button-active);
        --button-opacity: var(--opacity);
      }

      &[disabled] {
        background-color: transparent;
        --button-background: var(--button-disabled);
        --button-color: var(--button-disabled);
      }
    }

    &[data-appearance='text'] {
      //background-color: transparent;
      --button-background: transparent;
      --button-color: var(--button-color-text);

      svg {
        transition: 0.3s;
        color: var(--button-color);
        fill: var(--button-color);
      }

      &:hover:not(#{$parent}--loading):not(&:disabled) {
        background-color: transparent;
        --button-background: transparent;
        --button-color: var(--button-hover);
      }

      &:active:not(#{$parent}--loading):not(&:disabled) {
        background-color: transparent;
        --button-background: var(--button-active);
        --button-color: var(--button-active);
      }

      &[disabled] {
        pointer-events: none;
        background-color: transparent;
        --button-background: var(--button-disabled);
        --button-color: var(--button-disabled);
      }
    }
    &--color-main {
      &[data-appearance='fill'] {
        &:before {
          content: '';
          position: absolute;
          inset: 0;
          background-color: transparent;
          border-radius: var(--button-border-radius);
          transition: background-color 0.3s;
        }

        &:hover {
          opacity: var(--opacity);
          
          &:before {
            background-color: rgba(0, 0, 0, 0.3);
          }
        }

        &:active {
          opacity: var(--opacity);

          &:before {
            background-color: rgba(0, 0, 0, 0.4);
          }
        }

        &[disabled] {
          --button-disabled: #{$gradient-pink-purple-dark};
          opacity: var(--opacity-disabled);
        }
      }

      &:hover:not(&[data-appearance='fill']) {
        --button-hover: #{$main_purple_light};
        --button-color: #{$main_grey_light};
      }

      &:active:not(&[data-appearance='fill']) {
        --button-active: #{$main_purple_mid};
        --button-color: #{$main_grey_mid};
        opacity: 1;
      }

      &[disabled] {
        --button-disabled: #{$main_grey_dark};
      }
    }
    &--color-secondary {
      --button-color-outline: #{$secondary_red_light};
      --button-color-text: #{$secondary_red_light};
      --button-background: #{$secondary_red_light};
      --button-color: #{$main_white};

      &:hover {
        --button-hover: #{$secondary_red_mid};
        --button-color: #{$main_grey_mid};
      }

      &:active {
        --button-active: #{$secondary_red_dark};
        --button-color: #{$main_grey_dark};
      }

      &[disabled] {
        --button-disabled: #{$main_grey_dark};
      }
    }

    &--color-gradient {
      --button-color-outline: #{$main_white};
      --button-color-text: #{$main_white};
      --button-background: #{$main_white};
      --button-color: #{$main_black};
      box-shadow: none !important;

      @include gradientBorder();

      &:before {
        opacity: var(--button-opacity);
      }

      &:hover {
        --button-hover: #{$main_white};
        --button-color: #{$main_white};
        background-color: transparent !important;
        opacity: var(--opacity);
        box-shadow: $button-box-shadow-hover !important;
      }

      &:active {
        --button-active: #{$main_grey_dark};
        --button-color: #{$main_black};

        &:before {
          opacity: var(--button-opacity-active);
        }
      }

      &[disabled] {
        --button-disabled: #{$main_grey_dark};

        &:before {
          opacity: var(--button-opacity-disabled);
        }
      }
    }

    &--color-white {
      --button-color-outline: #{$main_white};
      --button-color-text: #{$main_white};
      --button-background: #{$main_white};
      --button-color: #{$main_black};

      &:hover {
        --button-hover: #{$main_grey_mid};
        --button-color: #{$main_black};
      }

      &:active {
        --button-active: #{$main_grey_dark};
        --button-color: #{$main_black};
      }

      &[disabled] {
        --button-disabled: #{$main_grey_dark};
      }
    }

    &--color-white-2 {
      --button-color-outline: #{$main_white};
      --button-color-text: #{$main_white};
      --button-background: #{$main_white};
      --button-color: #{$text-color-secondary};

      &:hover {
        --button-hover: #{$main_white};
        --button-color: #{$text-color-secondary};
        opacity: 1;
      }

      &:active {
        --button-active: #{$main_white};
        --button-color: #{$text-color-secondary};
        opacity: 1;
      }

      &[disabled] {
        --button-disabled: #{$main_grey_mid};
      }
    }

    &--color-gray {
      --button-color-outline: #{$secondary_grey_mid};
      --button-color-text: #{$secondary_grey_mid};
      --button-background: #{$secondary_grey_mid};
      --button-color: #{$main_white};

      &:hover {
        --button-hover: #{$secondary_grey_mid};
        --button-color: #{$main_grey_mid};
      }

      &:active {
        --button-active: #{$secondary_grey_dark};
        --button-color: #{$secondary_grey_light};
      }

      &[disabled] {
        --button-disabled: #{$main_grey_dark};
      }
    }

    &--color-transparent-08 {
      --button-color-outline: #{$transparent_08};
      --button-color-text: #{$transparent_08};
      --button-background: #{$transparent_08};
      --button-color: #{$main_white};

      &:active {
        --button-color: #{$secondary_grey_light};
      }
    }

    @each $size, $properties in $button-sizes {
      &[data-size='#{$size}'] {
        @each $property, $value in $properties {
          --#{$property}: #{$value};
        }
      }
    }

    &--rounded {
      width: fit-content;

      &#{$parent} {
        @each $size, $properties in $button-sizes {
          &[data-size='#{$size}'] {
            --button-padding: #{map-get($properties, 'rounded-padding')};
          }
        }
      }
    }

    @media (max-width: $retina) {
      @each $media, $properties in $button-sizes {
        &--media-#{$media} {
          &#{$parent} {
            @each $size in (large, normal, small) {
              &[data-size='#{$size}'] {
                --button-font-size: #{map-get($properties, 'button-font-size')};
                --button-padding: #{map-get($properties, 'button-padding')};
                --button-line-height: #{map-get($properties, 'button-line-height')};
                --button-border-radius: #{map-get($properties, 'button-border-radius')};
              }
            }

            &--rounded {
              @each $size in (large, normal, small) {
                &[data-size='#{$size}'] {
                  --button-padding: #{map-get($properties, 'rounded-padding')};
                }
              }
            }
          }
        }
      }
    }

    &--loading {
      pointer-events: none;
    }

    &--link {
      flex-direction: row-reverse;
    }
  }
</style>

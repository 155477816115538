import { default as indexafrlwOkeLAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexsLYHgY7xEkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/index.ts?macro=true";
import { default as PageFiltersklFrqokwcrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionCategories5ilHyDJ7xeMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionCategories.vue?macro=true";
import { default as SectionCollectionListy1HKMM3izTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionCollectionList.vue?macro=true";
import { default as SectionFiltersh6y4xYN7MxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionFilters.vue?macro=true";
import { default as SectionGenresGYt7vsdrCVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviesLV7NVfen56Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularNjLfdokegNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeriesw8fE2rvxYvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFiltersjNX25jISElMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenresbfSQszikQSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93BdM3VyiZZcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexWcdRXkvm7sMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93ylOBCIquIiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/collections/[slug].vue?macro=true";
import { default as indexYr40XQ32csMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/collections/index.vue?macro=true";
import { default as copyrightMJFnxuuZrWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/copyright.vue?macro=true";
import { default as _91id_93vC9lxpVcA8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/crew/[id].vue?macro=true";
import { default as indexMe7BLL7AXHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/faq/index.vue?macro=true";
import { default as allM53JnophOSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtOWBXFYQgTWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinuecFNH114QG3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoritesAjkTGqjQxLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionVieweduZ4J9fODoXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continue7aLiB90hokMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/continue.vue?macro=true";
import { default as indexoupDN4mFyBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/index.vue?macro=true";
import { default as purchasedKbFKPVUKpjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedmV76wR2jldMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policyKMz79ovEzAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesfeWnE84Mr2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionBannera621gx9v41Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionBanner.vue?macro=true";
import { default as SectionCollectionsIK6icW1N3tMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinueFrVJqrf9oYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilmsAwzSKW9eXnMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenresNFbRyx76tRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeaderZB8sQMVQptMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularl941eiFZIxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersRIFIww8smaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeriesuN6mToGVZOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptions8nQcq079LQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizeGpbrWuY8rdMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionTopMoviesjZMlUbdTffMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionTopMovies.vue?macro=true";
import { default as SectionUpcoming7TWeV0J8gDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as Indexw2cP3Aw3IpMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93PbADDSVH4vMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstallQPJzKs45qgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstall5mJGW9ASuYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as types2xYOOVeg6LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/components/types.ts?macro=true";
import { default as useInstallEkQLnsSSihMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrewxxy21DpKCKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexzvrjxWZEPOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollectionsFdG5HnZkAfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewaA4dG9MS2xMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionutYKnIWw1kMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeader0bP7jefrWiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNotice4YtOZeDVCNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeletonxoq82lQJsLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButtonurQx5Ij4L7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedZenbLWSE9VMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesYq2YLFaaH0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailersr3svacAuPkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsXUnBKbyl3LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_939xBYSqBjo0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/news/[slug].vue?macro=true";
import { default as indexH325SiqAB0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmspbqbNBjPO5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45seriesTbscFa9ibLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreementJBMUxwXjZXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightfOoZiwMHF3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holderj5fALyzReUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privateMKUgHL4duoMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-private.vue?macro=true";
import { default as policy_45userxzyh7wRzlzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-user.vue?macro=true";
import { default as premiersbnx4gYeeGbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/premiers.vue?macro=true";
import { default as private_45policyZMgBA7ikV2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/private-policy.vue?macro=true";
import { default as indextDbNebTIcvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModal9E0ILshApxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfileKkQGoLAaZTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModalNVNWgpxj9vMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VRemoveUserDatakc0cvWuqrcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VRemoveUserData.vue?macro=true";
import { default as VResumeSubscriptionSuccessModalaDjyHU1cdVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrer72wFJqIQbKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationO3wa8PfT6LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistoryFVfvCuqbVhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardSYtjxbi0xsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingsqR2mFNFVzGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPaymentsKWt5nn5LDwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabswHVl2vvDBkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgramt64lpwLLdVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionszPLiDsYGBVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexmijpqIf63HMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/devices/index.vue?macro=true";
import { default as indexyStESinWZ6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/index.vue?macro=true";
import { default as indexLxBE92DAwJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/notifications/index.vue?macro=true";
import { default as index48q7AoZhn7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/payments/index.vue?macro=true";
import { default as indexH7IVDqYMKjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/settings/index.vue?macro=true";
import { default as index2ZE3NtphmgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/user/index.vue?macro=true";
import { default as resultX5UexNnyoRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/result.vue?macro=true";
import { default as _91slug_93lkBbrPhDUvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/similar/[slug].vue?macro=true";
import { default as indexjPzaEdCfA5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/sports/channels/index.vue?macro=true";
import { default as indexL2c6UhVvi1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/sports/index.vue?macro=true";
import { default as SectionAdsuqJF2fT75aMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContentc7L4iF8hjUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevicesue9ECZgCcUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownloadnLcT8WGKOiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeader9RPPSJwCVyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptions5cnT5IYG1uMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceover3zYqkHc4KqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as indexCkinLQ9I1OMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/index.vue?macro=true";
import { default as termsCmpenIg6eWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/terms.vue?macro=true";
import { default as upcomingGEP8y2MDhVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCategories",
    path: "/browse/components/SectionCategories",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionCategories.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCollectionList",
    path: "/browse/components/SectionCollectionList",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionCollectionList.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionFilters",
    path: "/browse/components/SectionFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionBanner",
    path: "/home/components/SectionBanner",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionBanner.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionTopMovies",
    path: "/home/components/SectionTopMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionTopMovies.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indextDbNebTIcvMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRemoveUserData",
    path: "/profile/components/modal/VRemoveUserData",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VRemoveUserData.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexmijpqIf63HMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexyStESinWZ6Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexLxBE92DAwJMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: index48q7AoZhn7Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indexH7IVDqYMKjMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-user",
    path: "/profile/user",
    meta: index2ZE3NtphmgMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/profile/user/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexL2c6UhVvi1Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-432746/src/pages/upcoming.vue").then(m => m.default || m)
  }
]